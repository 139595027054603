<template>
    <div class="card__title title-second">Вход в личный кабинет</div>
    <div class="card__description">Выберите тип пользователя или если Вы первый раз на платформе, то подайте заявку на обучение</div>
    <div class="card__row">
        <div class="card-border">
            <img src="/img/icons/learner.svg" class="card-border__img">
            <div class="card-border__title text-big">Личный кабинет<br>
                <div class="strong text-blue">Обучаемого</div>
            </div>
            <RouterLink to="/login/student" class="btn btn-white">Выбрать</RouterLink>
        </div>
        <div class="card-border">
            <img src="/img/icons/customer.svg" class="card-border__img">
            <div class="card-border__title text-big">Личный кабинет<br>
                <div class="strong text-blue">Заказчика</div>
            </div>
            <RouterLink to="/login/customer" class="btn btn-white">Выбрать</RouterLink>
        </div>
    </div>
    <p class="text-big">или</p>
    <RouterLink to="/application" class="btn btn-red card__btn">Подать заявку на обучение</RouterLink>
</template>
